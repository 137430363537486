import { createReducer } from '@qover/common/utils/redux';
import { PRICES } from '@qover/common/constants/actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [PRICES.GET_PRICES]: state => ({
    ...state,
    isLoading: true,
  }),
  [PRICES.GET_PRICES_SUCCESS]: (state, { data }) => ({
    ...state,
    isLoading: false,
    data,
  }),
  [PRICES.GET_PRICES_FAILURE]: (state, { data }) => ({
    ...state,
    isLoading: false,
    error: data,
  }),
});
