import Cookie from 'js-cookie';
import { createReducer } from '@qover/common/utils/redux';
import {
  CHANGE_LANGUAGE_SUCCESS,
  ACCEPT_COOKIE_POLICY,
} from '@qover/common/constants/userConstant';

const initialState = {
  locale: 'en-BE',
  lang: 'en',
  messages: {},
  cookiePolicyAccepted: !!Cookie.get('accepted-cookie-policy'),
};

const reducer = createReducer(initialState, {
  [CHANGE_LANGUAGE_SUCCESS]: (state, payload) =>
    Object.assign({}, state, {
      locale: payload.locale,
      lang: payload.locale.substring(0, 2),
      messages: payload.messages,
    }),
  [ACCEPT_COOKIE_POLICY]: state => {
    Cookie.set('accepted-cookie-policy', 'true', { expires: 365 });
    return {
      ...state,
      cookiePolicyAccepted: true,
    };
  },
});

export default reducer;
