import Http from '@qover/common/services/http';
import { LANGUAGES } from '@qover/common/constants';
import { UI } from '@qover/common/constants/actionTypes';
import { defaultTheme } from '@qover/common/styles';

import Config from '../../utils/Config';

export const ACTIONS = Object.freeze({
  TOGGLE_YEARLY_MODE: 'PAI_TOGGLE_YEARLY_MODE',
  CREATE_DRAFT: 'CREATE_DRAFT',
  CREATE_DRAFT_SUCCESS: 'CREATE_DRAFT_SUCCESS',
  CREATE_DRAFT_FAILURE: 'CREATE_DRAFT_FAILURE',
  GET_DRAFT: 'GET_DRAFT',
  GET_DRAFT_SUCCESS: 'GET_DRAFT_SUCCESS',
  GET_DRAFT_FAILURE: 'GET_DRAFT_FAILURE',
  UPDATE_DRAFT: 'UPDATE_DRAFT',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_FAILURE: 'UPDATE_DRAFT_FAILURE',
  ADD_PERSON: 'ADD_PERSON',
  ADD_PERSON_SUCCESS: 'ADD_PERSON_SUCCESS',
  ADD_PERSON_FAILURE: 'ADD_PERSON_FAILURE',
  UPDATE_PERSON: 'UPDATE_PERSON',
  UPDATE_PERSON_SUCCESS: 'UPDATE_PERSON_SUCCESS',
  UPDATE_PERSON_FAILURE: 'UPDATE_PERSON_FAILURE',
  REMOVE_PERSON: 'REMOVE_PERSON',
  REMOVE_PERSON_SUCCESS: 'REMOVE_PERSON_SUCCESS',
  REMOVE_PERSON_FAILURE: 'REMOVE_PERSON_FAILURE',
  PRICE_REQUESTS: 'PRICE_REQUESTS',
  PRICE_REQUESTS_SUCCESS: 'PRICE_REQUESTS_SUCCESS',
  PRICE_REQUESTS_FAILURE: 'PRICE_REQUESTS_FAILURE',
  PRICE_INFO: 'PRICE_INFO',
  PRICE_INFO_SUCCESS: 'PRICE_INFO_SUCCESS',
  PRICE_INFO_FAILURE: 'PRICE_INFO_FAILURE',
  TOKEN_TERMS: 'TOKEN_TERMS',
  TOKEN_TERMS_SUCCESS: 'TOKEN_TERMS_SUCCESS',
  TOKEN_TERMS_FAILURE: 'TOKEN_TERMS_FAILURE',
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',
  GET_CONTRACT: 'GET_CONTRACT',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILURE: 'GET_CONTRACT_FAILURE',
  GET_PRODUCT_URLS: 'PAI_GET_PRODUCT_URLS',
  CLEAR_ERROR: 'PAI_CLEAR_ERROR',
  GET_BROKER: 'PAI_GET_BROKER',
  GET_BROKER_SUCCESS: 'PAI_GET_BROKER_SUCCESS',
  GET_BROKER_FAILURE: 'PAI_GET_BROKER_FAILURE',
});

const paiRequest = (method = 'get', props = {}) =>
  Http[method]({
    ...props,
    basePath: Config.paiBe.url,
  }).then(response => response.body);

export function toggleYearlyMode() {
  return dispatch => {
    dispatch({
      type: ACTIONS.TOGGLE_YEARLY_MODE,
    });
  };
}

export function clearError() {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
}

export function createDraft({ data }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.CREATE_DRAFT,
    });

    return paiRequest('post', { path: 'drafts', data: { ...data } })
      .then(response =>
        dispatch({
          type: ACTIONS.CREATE_DRAFT_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.CREATE_DRAFT_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function getDraft({ draftId }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.GET_DRAFT,
    });

    return paiRequest('get', { path: `drafts/${draftId}` })
      .then(response =>
        dispatch({
          type: ACTIONS.GET_DRAFT_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.GET_DRAFT_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function updateDraft({ draftId, data }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPDATE_DRAFT,
      payload: data,
    });
    return paiRequest('put', { path: `drafts/${draftId}`, data })
      .then(response =>
        dispatch({
          type: ACTIONS.UPDATE_DRAFT_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err => {
        dispatch({
          type: ACTIONS.UPDATE_DRAFT_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        });

        return Promise.reject(err);
      });
  };
}

export function addPerson({ draftId, data }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.ADD_PERSON,
    });

    return paiRequest('post', { path: `drafts/${draftId}/persons`, data })
      .then(response =>
        dispatch({
          type: ACTIONS.ADD_PERSON_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.ADD_PERSON_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function removePerson({ draftId, insuredPersonId }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.REMOVE_PERSON,
    });

    return paiRequest('delete', {
      path: `drafts/${draftId}/persons/${insuredPersonId}`,
    })
      .then(() =>
        dispatch({
          type: ACTIONS.REMOVE_PERSON_SUCCESS,
          payload: {
            insuredPersonId,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.REMOVE_PERSON_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function updatePerson({ draftId, personId, data }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.UPDATE_PERSON,
    });

    return paiRequest('put', {
      path: `drafts/${draftId}/persons/${personId}`,
      data,
    })
      .then(response =>
        dispatch({
          type: ACTIONS.UPDATE_PERSON_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err => {
        dispatch({
          type: ACTIONS.UPDATE_PERSON_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        });

        return Promise.reject(err);
      });
  };
}

export function priceRequests({ draftId }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.PRICE_REQUESTS,
    });

    return paiRequest('post', { path: `drafts/${draftId}/price-requests` })
      .then(response =>
        dispatch({
          type: ACTIONS.PRICE_REQUESTS_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.PRICE_REQUESTS_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function priceInfo() {
  return dispatch => {
    dispatch({
      type: ACTIONS.PRICE_INFO,
    });

    return paiRequest('get', { path: 'price-info' })
      .then(response => {
        dispatch({
          type: ACTIONS.PRICE_INFO_SUCCESS,
          payload: {
            data: response,
          },
        });
      })
      .catch(err =>
        dispatch({
          type: ACTIONS.PRICE_INFO_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function tokenTerms({ draftId }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.TOKEN_TERMS,
    });

    return paiRequest('post', { path: `drafts/${draftId}/token-terms` })
      .then(response =>
        dispatch({
          type: ACTIONS.TOKEN_TERMS_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.TOKEN_TERMS_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function getDocuments() {
  return dispatch => {
    dispatch({
      type: ACTIONS.GET_DOCUMENTS,
    });

    return paiRequest('get', { path: 'documents' })
      .then(response =>
        dispatch({
          type: ACTIONS.GET_DOCUMENTS_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.GET_DOCUMENTS_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

export function getContract({ contractId }) {
  return dispatch => {
    dispatch({
      type: ACTIONS.GET_CONTRACT,
    });

    return paiRequest('get', { path: `contracts/${contractId}` })
      .then(response =>
        dispatch({
          type: ACTIONS.GET_CONTRACT_SUCCESS,
          payload: {
            data: response,
          },
        }),
      )
      .catch(err =>
        dispatch({
          type: ACTIONS.GET_CONTRACT_FAILURE,
          payload: {
            error: err && err.response && err.response.body,
          },
        }),
      );
  };
}

const faqs = {
  en:
    'https://qover.zendesk.com/hc/en-us/categories/360000092818-Personal-accident-insurance-Belgium',
  fr:
    'https://qover.zendesk.com/hc/fr/categories/360000092818-Assurance-accident-personnelle',
  nl:
    'https://qover.zendesk.com/hc/nl/categories/360000092818-Persoonlijke-ongevallen-verzekering',
};

export function getProductUrls() {
  const localStorageLang = localStorage && localStorage.getItem('language');
  const userLang = localStorageLang || 'en-BE';
  const defaultLangItem = LANGUAGES[0]; // en-BE
  const languageToLoad =
    LANGUAGES.find(
      ({ value }) => value.toUpperCase() === userLang.toUpperCase(),
    ) || defaultLangItem;

  const data = {
    about: `${Config.qoverCorporateWebsite}/about`,
    conflicts: `${Config.qoverCorporateWebsite}/terms-policies/conflicts`,
    claim: `${Config.qoverCorporateWebsite}/terms-policies/notification`,
    contact: `${Config.qoverCorporateWebsite}/contact-us`,
    terms: `${Config.qoverCorporateWebsite}/terms-policies`,
    complaints: `${Config.qoverCorporateWebsite}/terms-policies/complaints`,
    faq: faqs[languageToLoad.short],
    facebook: 'https://www.facebook.com/qover.me',
    linkedin: 'https://www.linkedin.com/company/qover',
    twitter: 'https://twitter.com/qoverme',
  };

  return {
    type: ACTIONS.GET_PRODUCT_URLS,
    payload: {
      data,
    },
  };
}

export function getTheme() {
  return dispatch => {
    dispatch({
      type: UI.GET_THEME_REQUEST,
    });

    return paiRequest('get', { path: 'white-labels/website-theme' })
      .then(payload =>
        dispatch({
          type: UI.GET_THEME_SUCCESS,
          payload,
        }),
      )
      .catch(() =>
        dispatch({
          type: UI.GET_THEME_SUCCESS,
          payload: { colors: defaultTheme },
        }),
      );
  };
}
