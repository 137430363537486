import { createReducer } from '@qover/common/utils/redux';
import { TERMS } from '@qover/common/constants/actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [TERMS.GET_TERMS]: state => ({
    ...state,
    isLoading: true,
  }),
  [TERMS.GET_TERMS_SUCCESS]: (state, { data }) => ({
    ...state,
    isLoading: false,
    data,
  }),
  [TERMS.GET_TERMS_FAILURE]: (state, { data }) => ({
    ...state,
    isLoading: false,
    error: data,
  }),
});
