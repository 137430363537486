import formReducer from 'redux-form/es/reducer';
import generalReducer from './generalReducer';
import userReducer from './userReducer';
import productAgreementReducer from './productAgreementReducer';
import wizardReducer from './wizardReducer';
import documentsReducer from './documentsReducer';
import pricesReducer from './pricesReducer';
import draftReducer from './draftReducer';
import termsReducer from './termsReducer';
import uiReducer from './uiReducer';

export default {
  form: formReducer,
  general: generalReducer,
  productAgreement: productAgreementReducer,
  user: userReducer,
  wizard: wizardReducer,
  documents: documentsReducer,
  draft: draftReducer,
  prices: pricesReducer,
  terms: termsReducer,
  ui: uiReducer,
};
