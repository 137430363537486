export const defaultTheme = {
  error: { red: 238, blue: 87, green: 61 },
  primary: { red: 176, blue: 0, green: 194 },
  bigTitle: { red: 255, blue: 255, green: 255 },
  secondary: { red: 45, blue: 178, green: 109 },
  textQuote: { red: 77, blue: 82, green: 79 },
  footerText: { red: 255, blue: 255, green: 255 },
  cardBackground: { red: 255, blue: 255, green: 255 },
  smallFooterText: { red: 77, blue: 82, green: 79 },
  footerBackground: { red: 45, blue: 178, green: 109 },
  headerBackground: { red: 255, blue: 255, green: 255 },
  primaryBackground: { red: 245, blue: 249, green: 247 },
  primaryButtonText: { red: 255, blue: 255, green: 255 },
  secondaryButtonText: { red: 255, blue: 255, green: 255 },
};

export const qoverTheme = {
  bigTitle: {
    red: 255,
    green: 255,
    blue: 255,
  },
  cardBackground: {
    red: 255,
    green: 255,
    blue: 255,
  },
  error: {
    red: 238,
    green: 61,
    blue: 87,
  },
  footerBackground: {
    red: 243,
    green: 246,
    blue: 249,
  },
  footerText: {
    red: 91,
    green: 114,
    blue: 144,
  },
  headerBackground: {
    red: 49,
    green: 123,
    blue: 218,
  },
  primary: {
    red: 49,
    green: 207,
    blue: 218,
  },
  primaryBackground: {
    red: 49,
    green: 123,
    blue: 218,
  },
  primaryButtonText: {
    red: 255,
    green: 255,
    blue: 255,
  },
  secondary: {
    red: 255,
    green: 255,
    blue: 255,
  },
  secondaryButtonText: {
    red: 49,
    green: 207,
    blue: 219,
  },
  smallFooterText: {
    red: 255,
    green: 255,
    blue: 255,
  },
  textQuote: {
    red: 91,
    green: 114,
    blue: 144,
  },
};
