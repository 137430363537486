export const NEXT_STEP = 'NEXT_STEP';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';
export const SET_STEP = 'SET_STEP';

export function nextStep() {
  return {
    type: NEXT_STEP,
  };
}

export function previousStep() {
  return {
    type: PREVIOUS_STEP,
  };
}

export function setStep(stepNumber) {
  return {
    type: SET_STEP,
    payload: stepNumber,
  };
}
