import { createReducer } from '@qover/common/utils/redux';
import history from '@qover/common/utils/history';
import queryString from 'query-string';
import { ACTIONS } from './actions';

const initialState = {
  loading: false,
  error: null,
  draft: null,
  yearlyMode: queryString.parse(window.location.search).payMode === 'yearly',
};

export default createReducer(initialState, {
  [ACTIONS.UPDATE_DRAFT]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.UPDATE_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    draft: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.UPDATE_DRAFT_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.TOGGLE_YEARLY_MODE]: state => {
    const yearlyMode = !state.yearlyMode;
    const parsedQuery = queryString.parse(window.location.search);
    parsedQuery.payMode = yearlyMode ? 'yearly' : 'monthly';
    const stringifiedQuery = `?${queryString.stringify(parsedQuery)}`;
    history.replace(
      window.location.pathname + stringifiedQuery + window.location.hash,
    );
    return {
      ...state,
      yearlyMode,
    };
  },
});
