import request from 'superagent';

let instance = null;

class Config {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.initialized = false;

    return instance;
  }

  getConfig() {
    return request
      .get('/config.json')
      .then(res => res.body)
      .then(config => this.setConfig(config));
  }

  setConfig(config) {
    if (this.initialized || !Object.keys(config).length) {
      return;
    }

    Object.keys(config).forEach(k => {
      this[k] = Object.freeze(config[k]);
    });

    this.initialized = true;
  }
}

const singleton = new Config();

export default singleton;
