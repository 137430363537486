import queryString from 'query-string';
import get from 'lodash/get';
import { AUTH } from '../constants/actionTypes';
import history from '../utils/history';

export function logoutAndRedirect() {
  if (localStorage) {
    localStorage.removeItem('token');
  }
  return dispatch => {
    dispatch({ type: AUTH.LOGOUT_USER });
    history.push('/login');
  };
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function parseJSON(response) {
  return response.json();
}

export function parseText(response) {
  return response.text();
}

export function errorHandler(args, err) {
  if (!err) {
    throw new Error('HTTP UnknownError');
  }
  const convertedDraft = get(err, 'response.body.code') === 'DRAFT_CONVERTED';
  const isNotAuthorizedAndNotConverted = err.status === 403 && !convertedDraft;

  if (
    get(this, 'store.dispatch') &&
    (err.status === 401 || isNotAuthorizedAndNotConverted)
  ) {
    this.store.dispatch(logoutAndRedirect());
    return null;
  }

  let path = null;

  if (err.status === 403 && convertedDraft) {
    path = '/already-qovered';
  }

  if (path) {
    window.location = `${window.location.origin}${path}`;
  }

  const error = err instanceof Error ? err : new Error(err);

  if (window.Raven) {
    const message = error && error.message;
    if (err && err.response && err.response.body) {
      window.Raven.captureBreadcrumb({
        message: `body: ${message}`,
        category: 'http',
        data: err && err.response && err.response.body,
      });
    }
    window.Raven.captureBreadcrumb({
      message: `args: ${message}`,
      category: 'http',
      data: args,
    });
    window.Raven.captureException(error);
  } else {
    console.error(error.message); // eslint-disable-line no-console
  }
  throw error;
}

export const getPath = (path, { basePath }) => `${basePath}/${path}`;

export function getHeaders({ headers, options }) {
  let result = {
    ...headers,
  };

  if (options && options.headers) {
    result = {
      ...result,
      ...options.headers,
    };
  }

  if (window.location.hostname === 'localhost') {
    result['qover-partner'] = get(
      queryString.parse(window.location.search),
      'key',
      false,
    );
  }

  return result;
}

export function getSecureHeaders({ headers, auth, token }) {
  if (auth) {
    if (!token) {
      throw new Error('Missing token');
    }
    return {
      ...headers,
      authorization: `Bearer ${token}`,
    };
  }

  return {
    ...headers,
  };
}
