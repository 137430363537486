import { createReducer } from '@qover/common/utils/redux';
import { NEXT_STEP, PREVIOUS_STEP, SET_STEP } from '../actions/wizardActions';

const initialState = {
  currentStep: 0,
};

const reducer = createReducer(initialState, {
  [NEXT_STEP]: state =>
    Object.assign({}, state, {
      currentStep: state.currentStep + 1,
    }),
  [PREVIOUS_STEP]: state =>
    Object.assign({}, state, {
      currentStep: state.currentStep - 1,
    }),
  [SET_STEP]: (state, payload) =>
    Object.assign({}, state, {
      currentStep: payload,
    }),
});

export default reducer;
