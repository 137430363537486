import { createReducer } from '@qover/common/utils/redux';
import { DRAFT } from '@qover/common/constants/actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: false,
};

export default createReducer(initialState, {
  [DRAFT.RESET_DRAFT]: () => ({
    ...initialState,
  }),

  [DRAFT.RESET_DRAFT_ERRORS]: state => ({
    ...state,
    error: null,
  }),

  [DRAFT.REQUEST_DRAFT]: state => ({
    ...state,
    isLoading: true,
  }),
  [DRAFT.REQUEST_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  }),
  [DRAFT.REQUEST_DRAFT_FAILURE]: (state, { data }) => ({
    ...state,
    error: data,
    isLoading: false,
  }),

  [DRAFT.CREATE_DRAFT]: state => ({
    ...state,
    isLoading: true,
  }),
  [DRAFT.CREATE_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  }),
  [DRAFT.CREATE_DRAFT_FAILURE]: (state, { data }) => ({
    ...state,
    // error: data,
    isLoading: false,
  }),

  [DRAFT.UPDATE_DRAFT]: state => ({
    ...state,
    isLoading: true,
  }),
  [DRAFT.UPDATE_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  }),
  [DRAFT.UPDATE_DRAFT_FAILURE]: (state, { data }) => ({
    ...state,
    // error: data,
    isLoading: false,
  }),

  [DRAFT.SEND_LEADS]: state => ({
    ...state,
    isLoading: true,
  }),
  [DRAFT.SEND_LEADS_SUCCESS]: (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  }),
  [DRAFT.SEND_LEADS_FAILURE]: (state, { data }) => ({
    ...state,
    error: data,
    isLoading: false,
  }),
});
