import Http from '@qover/common/services/http';
import { UI } from '@qover/common/constants/actionTypes';
import { defaultTheme } from '@qover/common/styles';
import Config from '../utils/Config';

export const ACTIONS = Object.freeze({
  UPDATE_DRAFT: 'UPDATE_DRAFT',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_FAILURE: 'UPDATE_DRAFT_FAILURE',
  TOGGLE_YEARLY_MODE: 'LANDLORD_TOGGLE_YEARLY_MODE',
});

const productRequest = (method = 'get', props = {}) =>
  Http[method]({
    ...props,
    basePath: Config.landlordBe.url,
  }).then(response => response.body);

export function getTheme() {
  return dispatch => {
    dispatch({
      type: UI.GET_THEME_REQUEST,
    });

    return productRequest('get', { path: 'white-labels/website-theme' })
      .then(payload =>
        dispatch({
          type: UI.GET_THEME_SUCCESS,
          payload,
        }),
      )
      .catch(() =>
        dispatch({
          type: UI.GET_THEME_SUCCESS,
          payload: { colors: defaultTheme },
        }),
      );
  };
}

export function toggleYearlyMode(dispatch) {
  return dispatch({
    type: ACTIONS.TOGGLE_YEARLY_MODE,
  });
}
