import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { FormattedMessage } from './FormattedMessage';

// const ReactMarkdown = React.lazy(() => import('react-markdown'));

const linkToTabRenderer = {
  // There is content in the following link, it's passed through the "children" prop
  a: props => <a {...props} target="_blank" rel="noopener noreferrer" />, // eslint-disable-line jsx-a11y/anchor-has-content
};

export function FormattedMarkdown(props) {
  const { defaultMessage, values, ...restProps } = props;
  const options = {
    ...props.options,
    overrides: linkToTabRenderer,
  };
  if (props.options && props.options.overrides) {
    options.overrides = {
      ...linkToTabRenderer,
      ...props.options.overrides,
    };
  }
  return (
    <FormattedMessage {...props}>
      {message => (
        <div className="formattedMarkdown">
          <Markdown
            data-test={props['data-test']}
            options={options}
            {...restProps}
            source={message}
          >
            {message}
          </Markdown>
        </div>
      )}
    </FormattedMessage>
  );
}

FormattedMarkdown.propTypes = {
  'data-test': PropTypes.string,
  defaultMessage: PropTypes.string,
  options: PropTypes.object,
  values: PropTypes.object,
};
