import get from 'lodash/get';
import jwtDecode from 'jwt-decode';
import request from 'superagent';
import queryString from 'query-string';
import {
  errorHandler,
  getHeaders,
  getPath,
  getSecureHeaders,
  logoutAndRedirect,
} from '../services/httpHelpers';

let instance = null;

class Http {
  static getApiKeyProp(key) {
    const query = queryString.parse(window.location.search);
    const language = get(query, 'language', '');
    const apikey = key || get(query, 'key', false);
    const nextQuery = {};
    if (language) {
      nextQuery.language = language;
    }
    if (apikey) {
      nextQuery.apikey = apikey;
    }
    return `?${queryString.stringify(nextQuery)}`;
  }

  static getType(type) {
    let t = null;
    if (type) {
      t = type === 'multipart/form-data' ? undefined : type;
    } else {
      t = 'application/json';
    }

    return t;
  }

  constructor() {
    if (!instance) {
      instance = this;
    }

    this.token = null;
    this.store = null;
    this.headers = {
      'qover-access-type': 'secret',
    };

    return instance;
  }

  get({ path, basePath = '', auth = false, params = {} }) {
    if (!path) {
      return Promise.reject(new Error('No path found'));
    }
    const options = {
      basePath,
    };

    // TODO: this is a good candidate for function composition
    const headers = getSecureHeaders({
      headers: getHeaders({ headers: this.headers, options }),
      auth,
      token: this.token,
    });
    const apikeyProp = Http.getApiKeyProp();

    const search = {
      ...queryString.parse(apikeyProp),
      ...params,
    };

    // The following variable is the Internet Explorer Cache Buster, iecb for friends
    // Some versions of IE11 decided to not implement cache directives the same as everyone else
    // So iecb is there so that those version of ie thinks we're making an other request each time
    // For more details, see:
    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12246819/
    const iecb = { iecb: Date.now() };
    const uri = getPath(`${path}?${queryString.stringify(search)}`, options);
    const req = request
      .get(uri)
      .responseType(options.responseType || undefined)
      .type('application/json')
      .accept('application/json')
      .query({ ...iecb, ...(options ? options.query : {}) })
      .set(headers)
      .catch(errorHandler.bind(this, [...arguments])); // eslint-disable-line prefer-rest-params

    return req;
  }

  delete({ path, data, basePath, auth = false }) {
    const options = {
      basePath,
    };
    // TODO: this is a good candidate for function composition
    const headers = getSecureHeaders({
      headers: getHeaders({ headers: this.headers, options }),
      auth,
      token: this.token,
    });
    const apikeyProp = Http.getApiKeyProp();

    const uri = getPath(`${path}${apikeyProp}`, options);
    const req = request
      .delete(uri)
      .type('application/json')
      .accept('application/json')
      .set(headers)
      .send(data)
      .catch(errorHandler.bind(this, [...arguments])); // eslint-disable-line prefer-rest-params

    return req;
  }

  post({ path, data, basePath, auth = false, params = {}, apikey }) {
    const options = {
      basePath,
    };
    // TODO: this is a good candidate for function composition
    const headers = getSecureHeaders({
      headers: getHeaders({ headers: this.headers, options }),
      auth,
      token: this.token,
    });
    const apikeyProp = Http.getApiKeyProp(apikey);

    const uri = getPath(`${path}${apikeyProp}`, options);
    const req = request
      .post(uri)
      .type('application/json')
      .query(params)
      .accept('application/json')
      .set(headers)
      .send(data)
      .catch(errorHandler.bind(this, [...arguments])); // eslint-disable-line prefer-rest-params

    return req;
  }

  put({ path, data, basePath, auth = false }) {
    const options = {
      basePath,
    };
    const headers = getHeaders({
      headers: this.headers,
      options,
      auth,
      token: this.token,
    });
    const apikeyProp = Http.getApiKeyProp();

    const uri = getPath(`${path}${apikeyProp}`, options); // eslint-disable-line no-undef
    const req = request
      .put(uri)
      .type('application/json')
      .accept('application/json')
      .set(headers)
      .send(data)
      .catch(errorHandler.bind(this, [...arguments])); // eslint-disable-line prefer-rest-params

    return req;
  }

  setStore(store) {
    if (store && this.store == null) {
      this.store = store;
    }
    return this;
  }

  setToken(token) {
    let decoded = null;

    try {
      decoded = jwtDecode(token);

      if (decoded.exp * 1000 < new Date()) {
        throw new Error('Expired token');
      }
    } catch (ex) {
      if (this.store && this.store.dispatch) {
        this.store.dispatch(logoutAndRedirect());
      }

      return null;
    }

    this.token = token;
    return this;
  }
}

const singleton = new Http();

export default singleton;
