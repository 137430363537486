import { createElement } from 'react';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';

import { escapeId, isPhraseEnabled } from './functions';

export class FormattedMessage extends IntlFormattedMessage {
  render() {
    if (isPhraseEnabled()) {
      const nodes = [escapeId(this.props.id)];
      return createElement('span', null, ...nodes);
    }
    return super.render();
  }
}
