import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class Meta extends React.Component {
  setDescription() {
    const { id, defaultMessage, intl } = this.props;
    const content = intl.messages[id] || defaultMessage;
    this.element.setAttribute('content', content);
  }
  componentDidMount() {
    this.setDescription();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.id !== prevProps.id ||
      this.props.defaultMessage !== prevProps.defaultMessage
    ) {
      this.setDescription();
    }
  }
  componentWillMount() {
    this.element = document.createElement('meta');
    document.getElementsByTagName('head')[0].appendChild(this.element);
    this.element.setAttribute('name', 'description');
  }
  componentWillUnmount() {
    this.element.parentNode.removeChild(this.element);
  }
  render() {
    return null;
  }
}

Meta.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  intl: PropTypes.any.isRequired,
};

export const FormattedMetaDescription = injectIntl(Meta);
