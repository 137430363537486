export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

export function hideModal(modalName) {
  return {
    type: HIDE_MODAL,
    payload: {
      modalName,
    },
  };
}

export function showModal(modalName) {
  return {
    type: SHOW_MODAL,
    payload: {
      modalName,
    },
  };
}
