import { createReducer } from '@qover/common/utils/redux';
import { DOCUMENTS } from '@qover/common/constants/actionTypes';

const initialState = {
  data: [],
};

export default createReducer(initialState, {
  [DOCUMENTS.GET_DOCUMENTS_URL_REQUEST]: state => ({
    ...state,
  }),
  [DOCUMENTS.GET_DOCUMENTS_URL_SUCCESS]: (state, { data }) => ({
    ...state,
    data,
  }),
  [DOCUMENTS.GET_DOCUMENTS_URL_FAILURE]: (state, { data }) => ({
    ...state,
    error: data,
  }),
});
