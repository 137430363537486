import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import joblossReducers from './jobloss/reducer';
import paiReducers from './pai/be/reducer';
import landlordReducers from './landlord/reducer';

const reducer = combineReducers({
  documents: reducers.documents,
  draft: reducers.draft,
  form: reducers.form,
  general: reducers.general,
  jobloss: joblossReducers,
  landlord: landlordReducers,
  pai: paiReducers,
  prices: reducers.prices,
  productAgreement: reducers.productAgreement,
  terms: reducers.terms,
  ui: reducers.ui,
  user: reducers.user,
  wizard: reducers.wizard,
});

// Note: passing middleware as the last argument requires redux@>=3.1.0
/* eslint-disable no-underscore-dangle */
const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable no-underscore-dangle */
const composeEnhancers = devTools || compose;

let store = null;

const createQoverStore = initialState => {
  if (!store) {
    store = createStore(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(thunk)),
    );
  }
  return store;
};

export default createQoverStore;
