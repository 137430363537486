import { createReducer } from '@qover/common/utils/redux';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';
import { ACTIONS } from './actions';

const initialState = {
  draft: null,
  price: null,
  prices: null,
  loading: false,
  error: null,
  contract: null,
  persons: [],
  yearlyMode: false,
  documents: [],
  terms: null,
  urls: null,
  broker: null,
  requestQueue: [],
};

export default createReducer(initialState, {
  [ACTIONS.TOGGLE_YEARLY_MODE]: state => ({
    ...state,
    yearlyMode: !state.yearlyMode,
  }),
  [ACTIONS.CLEAR_ERROR]: state => ({
    ...state,
    error: null,
  }),
  [ACTIONS.CREATE_DRAFT]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.CREATE_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    draft: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.CREATE_DRAFT_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.GET_DRAFT]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.GET_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    draft: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.GET_DRAFT_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.UPDATE_DRAFT]: (state, action) => {
    const isLanguageOnlyUpdate =
      has(action, 'language') && Object.keys(action).length === 1;
    return {
      ...state,
      loading: true,
      requestQueue: isLanguageOnlyUpdate
        ? [...state.requestQueue]
        : [...state.requestQueue, {}],
    };
  },
  [ACTIONS.UPDATE_DRAFT_SUCCESS]: (state, { data }) => ({
    ...state,
    draft: data,
    loading: false,
    error: null,
    requestQueue: [...state.requestQueue.slice(0, -1)],
  }),
  [ACTIONS.UPDATE_DRAFT_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
    requestQueue: [...state.requestQueue.slice(0, -1)],
  }),
  [ACTIONS.ADD_PERSON]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.ADD_PERSON_SUCCESS]: (state, { data }) => ({
    ...state,
    loading: false,
    draft: {
      ...state.draft,
      risk: {
        ...state.draft.risk,
        insuredPersons: [...state.draft.risk.insuredPersons, data],
      },
    },
    error: null,
  }),
  [ACTIONS.ADD_PERSON_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.UPDATE_PERSON]: state => ({
    ...state,
    loading: true,
    requestQueue: [...state.requestQueue, {}],
  }),
  [ACTIONS.UPDATE_PERSON_SUCCESS]: (state, { data }) => {
    const personIndex = findIndex(state.draft.risk.insuredPersons, {
      insuredPersonId: data.insuredPersonId,
    });
    return {
      ...state,
      loading: false,
      draft: {
        ...state.draft,
        risk: {
          ...state.draft.risk,
          insuredPersons: [
            ...state.draft.risk.insuredPersons.slice(0, personIndex),
            data,
            ...state.draft.risk.insuredPersons.slice(personIndex + 1),
          ],
        },
      },
      error: null,
      requestQueue: [...state.requestQueue.slice(0, -1)],
    };
  },
  [ACTIONS.UPDATE_PERSON_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
    requestQueue: [...state.requestQueue.slice(0, -1)],
  }),
  [ACTIONS.REMOVE_PERSON]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.REMOVE_PERSON_SUCCESS]: (state, { insuredPersonId }) => ({
    ...state,
    loading: false,
    draft: {
      ...state.draft,
      risk: {
        ...state.draft.risk,
        insuredPersons: [
          ...state.draft.risk.insuredPersons.filter(
            x => x.insuredPersonId !== insuredPersonId,
          ),
        ],
      },
    },
    error: null,
  }),
  [ACTIONS.PRICE_REQUESTS]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.PRICE_REQUESTS_SUCCESS]: (state, { data }) => ({
    ...state,
    price: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.PRICE_REQUESTS_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.PRICE_INFO]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.PRICE_INFO_SUCCESS]: (state, { data }) => ({
    ...state,
    prices: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.PRICE_INFO_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.TOKEN_TERMS]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.TOKEN_TERMS_SUCCESS]: (state, { data }) => ({
    ...state,
    terms: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.TOKEN_TERMS_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.GET_DOCUMENTS]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.GET_DOCUMENTS_SUCCESS]: (state, { data }) => ({
    ...state,
    documents: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.GET_DOCUMENTS_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.GET_CONTRACT]: state => ({
    ...state,
    loading: true,
  }),
  [ACTIONS.GET_CONTRACT_SUCCESS]: (state, { data }) => ({
    ...state,
    contract: data,
    loading: false,
    error: null,
  }),
  [ACTIONS.GET_CONTRACT_FAILURE]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [ACTIONS.GET_PRODUCT_URLS]: (state, { data }) => ({
    ...state,
    loading: false,
    error: null,
    urls: data,
  }),
  [ACTIONS.GET_BROKER_SUCCESS]: (state, { data }) => ({
    ...state,
    broker: data,
  }),
});
