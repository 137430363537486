import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class Title extends React.Component {
  setTitle() {
    const { id, defaultMessage, intl } = this.props;
    const content = intl.messages[id] || defaultMessage;
    this.element.innerText = content;
  }
  componentDidMount() {
    this.setTitle();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.id !== prevProps.id ||
      this.props.defaultMessage !== prevProps.defaultMessage
    ) {
      this.setTitle();
    }
  }
  componentWillMount() {
    this.element = document.getElementsByTagName('title')[0];
    if (!this.element) {
      this.element = document.createElement('title');
      document.getElementsByTagName('head')[0].appendChild(this.element);
    }
    this.originalContent = this.element.innerText;
  }
  componentWillUnmount() {
    this.element.innerText = this.originalContent;
  }
  render() {
    return null;
  }
}

Title.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  intl: PropTypes.any.isRequired,
};

export const FormattedTitle = injectIntl(Title);
