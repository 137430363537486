import merge from 'lodash/merge';
import { createReducer } from '@qover/common/utils/redux';
import { UI } from '@qover/common/constants/actionTypes';
import { defaultTheme } from '@qover/common/styles';

const initialState = {
  'gap-rti': null,
  landlord: null,
  jobloss: null,
  paddingStickyStepper: false,
  loadingTheme: false,
  theme: {
    logo: 'images/qover-logo-white-horizontal.svg',
    colors: defaultTheme,
  },
};

export default createReducer(initialState, {
  [UI.GET_PRODUCT_URLS]: (state, { productName, data }) => ({
    ...state,
    [productName]: {
      ...state[productName],
      urls: {
        ...data,
      },
    },
  }),
  [UI.ADD_PADDING_STICKY_STEPPER]: state => ({
    ...state,
    paddingStickyStepper: true,
  }),

  [UI.REMOVE_PADDING_STICKY_STEPPER]: state => ({
    ...state,
    paddingStickyStepper: false,
  }),
  [UI.GET_THEME_REQUEST]: state => ({
    ...state,
    loadingTheme: true,
    theme: {
      ...state.theme,
      sync: false,
    },
  }),
  [UI.GET_THEME_FAILURE]: state => ({
    ...state,
    loadingTheme: true,
    theme: {
      ...state.theme,
      sync: true,
    },
  }),
  [UI.GET_THEME_SUCCESS]: (state, payload) => ({
    ...state,
    loadingTheme: false,
    theme: {
      logo: payload.logo || state.theme.logo,
      featureToggles: payload.featureToggles || state.theme.featureToggles,
      colors: merge(defaultTheme, payload.colors),
      sync: true,
    },
  }),
});
