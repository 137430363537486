import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import 'promise-polyfill/src/polyfill';
import history from '@qover/common/utils/history';
import createStore from './store';

const root = document.getElementById('root');
export function init(App) {
  ReactDOM.render(
    <Provider store={createStore({})}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    root,
  );
}
