import { createReducer } from '@qover/common/utils/redux';
import { HIDE_MODAL, SHOW_MODAL } from '../actions/generalActions';

const initialState = {
  modals: {},
};

const reducer = createReducer(initialState, {
  [HIDE_MODAL]: (state, payload) => ({
    ...state,
    modals: {
      ...state.modals,
      [payload.modalName]: false,
    },
  }),
  [SHOW_MODAL]: (state, payload) => ({
    ...state,
    modals: {
      ...state.modals,
      [payload.modalName]: true,
    },
  }),
});

export default reducer;
