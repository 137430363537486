export const CHANGE_LANGUAGE_REQUEST = 'CHANGE_LANGUAGE_REQUEST';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAILURE = 'CHANGE_LANGUAGE_FAILURE';
export const CHANGE_LANGUAGE_BY_USER_REQUEST =
  'CHANGE_LANGUAGE_BY_USER_REQUEST';
export const CHANGE_LANGUAGE_BY_USER_SUCCESS =
  'CHANGE_LANGUAGE_BY_USER_SUCCESS';
export const CHANGE_LANGUAGE_BY_USER_FAILURE =
  'CHANGE_LANGUAGE_BY_USER_FAILURE';
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS';
export const GET_BRANDING_FAILURE = 'GET_BRANDING_FAILURE';
export const ACCEPT_COOKIE_POLICY = 'ACCEPT_COOKIE_POLICY';

export default {
  CHANGE_LANGUAGE_REQUEST,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILURE,
};
