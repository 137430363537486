export const AUTH = {
  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',
  INIT_COMPLETED: 'INIT_COMPLETED',
  LOGOUT_USER: 'LOGOUT_USER',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
};

export const CONTRACT = {
  GET_ALL_CONTRACT_REQUEST: 'GET_ALL_CONTRACT_REQUEST',
  GET_ALL_CONTRACT_FAILURE: 'GET_ALL_CONTRACT_FAILURE',
  GET_ALL_CONTRACT_SUCCESS: 'GET_ALL_CONTRACT_SUCCESS',
  GET_CONTRACT_REQUEST: 'GET_CONTRACT_REQUEST',
  GET_CONTRACT_FAILURE: 'GET_CONTRACT_FAILURE',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CANCEL_REQUEST: 'GET_CANCEL_REQUEST',
  GET_CANCEL_FAILURE: 'GET_CANCEL_FAILURE',
  GET_CANCEL_SUCCESS: 'GET_CANCEL_SUCCESS',
  CANCEL_REQUEST: 'CANCEL_REQUEST',
  CANCEL_FAILURE: 'CANCEL_FAILURE',
  CANCEL_SUCCESS: 'CANCEL_SUCCESS',
};

export const DRAFT = {
  ADD_METADATA_REQUEST: 'ADD_METADATA_REQUEST',
  ADD_METADATA_FAILURE: 'ADD_METADATA_FAILURE',
  ADD_METADATA_SUCCESS: 'ADD_METADATA_SUCCESS',
  RESET_DRAFT: 'RESET_DRAFT',
  REQUEST_DRAFT: 'REQUEST_DRAFT',
  REQUEST_DRAFT_SUCCESS: 'REQUEST_DRAFT_SUCCESS',
  REQUEST_DRAFT_FAILURE: 'REQUEST_DRAFT_FAILURE',
  CREATE_DRAFT: 'CREATE_DRAFT',
  CREATE_DRAFT_SUCCESS: 'CREATE_DRAFT_SUCCESS',
  CREATE_DRAFT_FAILURE: 'CREATE_DRAFT_FAILURE',
  UPDATE_DRAFT: 'UPDATE_DRAFT',
  UPDATE_DRAFT_SUCCESS: 'UPDATE_DRAFT_SUCCESS',
  UPDATE_DRAFT_FAILURE: 'UPDATE_DRAFT_FAILURE',
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  CREATE_CONTRACT_SUCCESS: 'CREATE_CONTRACT_SUCCESS',
  CREATE_CONTRACT_FAILURE: 'CREATE_CONTRACT_FAILURE',
  SEND_LEADS: 'SEND_LEADS',
  SEND_LEADS_SUCCESS: 'SEND_LEADS_SUCCESS',
  SEND_LEADS_FAILURE: 'SEND_LEADS_FAILURE',
  RESET_DRAFT_ERRORS: 'RESET_DRAFT_ERRORS',
};

export const GENERAL = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const INSURER = {
  GET_INSURERS_SUCCESS: 'GET_INSURERS_SUCCESS',
  GET_INSURER_SUCCESS: 'GET_INSURER_SUCCESS',
};

export const INTL = {
  CHANGE_LANGUAGE_SUCCESS: 'CHANGE_LANGUAGE_SUCCESS',
};

export const LOGS = {
  GET_LOGS_REQUEST: 'GET_LOGS_REQUEST',
  GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS',
  GET_LOGS_FAILURE: 'GET_LOGS_FAILURE',
};

export const PRICES = {
  RESET_PRICES: 'RESET_PRICES',
  GET_PRICES: 'GET_PRICES',
  GET_PRICES_SUCCESS: 'GET_PRICES_SUCCESS',
  GET_PRICES_FAILURE: 'GET_PRICES_FAILURE',
};

export const TERMS = {
  GET_TERMS: 'GET_TERMS',
  GET_TERMS_SUCCESS: 'GET_TERMS_SUCCESS',
  GET_TERMS_FAILURE: 'GET_TERMS_FAILURE',
};

export const DOCUMENTS = {
  GET_DOCUMENTS_URL_FAILURE: 'GET_DOCUMENTS_URL_FAILURE',
  GET_DOCUMENTS_URL_REQUEST: 'GET_DOCUMENTS_URL_REQUEST',
  GET_DOCUMENTS_URL_SUCCESS: 'GET_DOCUMENTS_URL_SUCCESS',
};

export const UI = {
  ADD_PADDING_STICKY_STEPPER: 'ADD_PADDING_STICKY_STEPPER',
  GET_PRODUCT_URLS: 'GET_PRODUCT_URLS',
  GET_THEME_FAILURE: 'GET_THEME_FAILURE',
  GET_THEME_REQUEST: 'GET_THEME_REQUEST',
  GET_THEME_SUCCESS: 'GET_THEME_SUCCESS',
  REMOVE_PADDING_STICKY_STEPPER: 'REMOVE_PADDING_STICKY_STEPPER',
};
