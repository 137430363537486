import React from 'react';
import { defineMessages, FormattedMessage } from '@qover/react-intl';

export const FORM_NAME = 'product-form';
export const PAYMENT_FORM_NAME = 'payment-form';

export const COUNTRIES = [
  {
    label: 'Belgium',
    labelId: 'belgium',
    value: 'BE',
    defaultMessage: 'Belgium',
    formatMessageId: 'belgium',
  },
];

export const LANGUAGES = [
  {
    label: 'Dutch',
    labelId: 'dutch',
    defaultMessage: 'Dutch',
    formatMessageId: 'dutch',
    value: 'nl-BE',
    short: 'nl',
  },
  {
    label: 'English',
    labelId: 'english',
    defaultMessage: 'English',
    formatMessageId: 'english',
    value: 'en-BE',
    short: 'en',
  },
  {
    label: 'French',
    labelId: 'french',
    defaultMessage: 'French',
    formatMessageId: 'french',
    value: 'fr-BE',
    short: 'fr',
  },
];

export const PROPERTY_SECOND_TYPES = [
  {
    id: 'studio',
    label: <FormattedMessage id="studio" defaultMessage="Studio" />,
    value: 'studio',
  },
  {
    id: 'flat',
    label: <FormattedMessage id="flat" defaultMessage="Flat" />,
    value: 'flat',
  },
  {
    id: 'house',
    label: <FormattedMessage id="house" defaultMessage="House" />,
    value: 'house',
  },
];

export const TITLES = [
  {
    label: defineMessages({ id: 'mr' }),
    value: 'Mr',
  },
  {
    label: defineMessages({ id: 'mrs' }),
    value: 'Mrs',
  },
];

export const GENDERS = [
  {
    label: 'Male',
    labelId: 'male',
    defaultMessage: 'Male',
    formatMessageId: 'male',
    value: 'Mr',
  },
  {
    label: 'Female',
    labelId: 'female',
    defaultMessage: 'Female',
    formatMessageId: 'female',
    value: 'Mrs',
  },
];

export const APIS = {
  GOOGLE: {
    APIKEY: 'AIzaSyDxlESqgmHdLb5-aosc4zhAH-f8DPLwC9I',
  },
};

export const INDUSTRIES = [
  {
    label: defineMessages({ id: 'general.industry.agriculture' }),
    value: 'Agriculture, Forestry or Fishing',
  },
  {
    label: defineMessages({ id: 'general.industry.mining' }),
    value: 'Mining or Quarrying',
  },
  {
    label: defineMessages({ id: 'general.industry.electricity' }),
    value: 'Electricity, Gas, Steam or Air conditioning supply',
  },
  {
    label: defineMessages({ id: 'general.industry.manufacturing' }),
    value: 'Manufacturing',
  },
  {
    label: defineMessages({ id: 'general.industry.waterSupply' }),
    value: 'Water supply, sewerage, waste management ...',
  },
  {
    label: defineMessages({ id: 'general.industry.construction' }),
    value: 'Construction',
  },
  {
    label: defineMessages({ id: 'general.industry.wholesale' }),
    value: 'Wholesale or retail trade, repair of motor vehicles',
  },
  {
    label: defineMessages({ id: 'general.industry.transportation' }),
    value: 'Transportation and storage',
  },
  {
    label: defineMessages({ id: 'general.industry.accomodations' }),
    value: 'Accomodations or food services activities',
  },
  {
    label: defineMessages({ id: 'general.industry.information' }),
    value: 'Information and communication',
  },
  {
    label: defineMessages({ id: 'general.industry.finance' }),
    value: 'Finance or Insurance',
  },
  {
    label: defineMessages({ id: 'general.industry.realEstate' }),
    value: 'Real Estate',
  },
  {
    label: defineMessages({ id: 'general.industry.scientific' }),
    value: 'Scientific or Technical',
  },
  {
    label: defineMessages({ id: 'general.industry.administrative' }),
    value: 'Administrative or support',
  },
  {
    label: defineMessages({ id: 'general.industry.publicAdministration' }),
    value: 'Public Administration, Defense or Social Security',
  },
  {
    label: defineMessages({ id: 'general.industry.education' }),
    value: 'Education',
  },
  {
    label: defineMessages({ id: 'general.industry.medical' }),
    value: 'Medical and health industry',
  },
  {
    label: defineMessages({ id: 'general.industry.art' }),
    value: 'Art or Entertainment',
  },
  {
    label: defineMessages({ id: 'general.industry.it' }),
    value: 'Information Technology',
  },
  {
    label: defineMessages({ id: 'general.industry.humanResources' }),
    value: 'Human Resources',
  },
  {
    label: defineMessages({ id: 'general.industry.other' }),
    value: 'Other',
  },
];

export const mastercard = {
  name: 'mastercard',
  src: '/assets/images/mastercard.png',
  text: <FormattedMessage id="general.payment.paymentMethod.mastercard" />,
  type: 'credit-card',
};
export const visa = {
  name: 'visa',
  src: '/assets/images/visa.png',
  text: <FormattedMessage id="general.payment.paymentMethod.visa" />,
  type: 'credit-card',
};
export const bancontact = {
  name: 'bancontact',
  src: '/assets/images/bancontact.png',
  text: <FormattedMessage id="general.payment.paymentMethod.bancontact" />,
  type: 'bancontact',
};
export const amex = {
  name: 'amex',
  src: '/assets/images/amex.png',
  text: <FormattedMessage id="general.payment.paymentMethod.amex" />,
  type: 'credit-card',
};

export const CREDIT_CARD = [mastercard, visa, bancontact, amex];
