import { createReducer } from '@qover/common/utils/redux';
import { SET_PARTNER } from '../actions/productAgreementActions';

const initialState = {
  partner: 'qover',
};

const reducer = createReducer(initialState, {
  [SET_PARTNER]: (state, payload) => ({
    ...state,
    partner: payload,
  }),
});

export default reducer;
