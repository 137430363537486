let phraseAppEditor = false;

function sanitizeConfig(config) {
  const sanitizedConf = config;
  sanitizedConf.prefix = config.prefix ? config.prefix : '{{__';
  sanitizedConf.suffix = config.suffix ? config.suffix : '__}}';

  return sanitizedConf;
}

export function initializePhraseAppEditor(config) {
  if (phraseAppEditor) return;

  phraseAppEditor = true;
  window.PHRASEAPP_ENABLED = config.phraseEnabled;
  window.PHRASEAPP_CONFIG = sanitizeConfig(config);

  if (config.phraseEnabled) {
    const phraseapp = document.createElement('script');
    phraseapp.type = 'text/javascript';
    phraseapp.async = true;
    phraseapp.src = [
      'https://',
      'phraseapp.com/assets/in-context-editor/2.0/app.js?',
      new Date().getTime(),
    ].join('');
    const s = document.getElementsByTagName('script')[0];
    // code pasted from react-intl-phraseapp
    if (s != undefined) {
      // eslint-disable-line eqeqeq
      s.parentNode.insertBefore(phraseapp, s);
    } else {
      document.insertBefore(phraseapp, null);
    }
  }
}

export function isPhraseEnabled() {
  return window.PHRASEAPP_ENABLED;
}

export function escapeId(id) {
  const config = window.PHRASEAPP_CONFIG;
  return `${config.prefix}phrase_${id}${config.suffix}`;
}
